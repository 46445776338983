<script>
	import Layout from '../../layouts/main'
	import PageHeader from '@/components/page-header'
	import appConfig from '@/app.config'
	import Multiselect from 'vue-multiselect'

	export default {
		middleware: 'authentication',
		page: {
			title: 'Onboarding List',
			meta: [
				{
					name: 'description',
					content: appConfig.description,
				},
			],
		},
		components: {
			Layout,
			PageHeader,
			Multiselect,
		},
		data: function() {
			return {
				items: [
					{
						text: "View",
					},
					{
						text: "Onboarding List",
						active: true,
					},
				],
				corporateId: '',
				isReportTableBusy: false,
				excelDownloading: false,
				totalRows: 1,
				currentPage: 1,
				perPage: 10,
				pageOptions: [10, 25, 50, 100, 500],
				tableData: [],
				moment: this.$moment,
				filter: null,
				filterOn: [],
				sortBy: "createdAt",
				sortDesc: true,
				employeeFilterBranch: [],
				selectedBranch: null,
				searchQuery: '',
				fields: [
					{
						key: 'actions',
						sortable: false,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'name',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'phoneNumber',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'designation',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'annualCtc',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'offerLetterAcceptRejectStatus',
						label: 'Offer Letter Status',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'joiningDate',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'offerExpiry',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
					{
						key: 'createdAt',
						sortable: true,
						tdClass: "align-center",
						thStyle: {
							backgroundColor: '#f6f6f6',
						},
					},
				],
			}
		},
		computed: {
			rows: function() {
				return this.totalRows;
			},
		},
		mounted: function() {
			this.corporateId = localStorage.getItem('corporateId')
			this.getBranchList()
		},
		methods: {
			getBranchList: function() {
				this.axios.post('corporate/get-branch', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
				}).then(result => {
					console.log(result.data)
					this.employeeFilterBranch = result.data.data
					this.selectedBranch = this.employeeFilterBranch[0]
					this.getOnboardingJourneyList()
				}).catch(error => {
					console.log(error)
				})
			},
			getOnboardingJourneyList: function() {
				this.axios.post('/onboard-employee/list', {
					corporateId: (this.corporateId !== undefined && this.corporateId !== null) ? this.corporateId : this.$storageData.corporateId,
					branchId: this.selectedBranch._id,
					page: this.currentPage,
					pageSize: this.perPage,
					searchKeyword: this.searchQuery,
				}).then((result) => {
					this.tableData = result.data.data.listing
					this.totalRows = result.data.data.totalCounts
				}).catch((error) => {
					console.log(error)
				})
			},
			changePage: function(value) {
				this.currentPage = value
				this.getOnboardingJourneyList()
			},
			searchFilter: function(value) {
				this.searchQuery = value
				this.getOnboardingJourneyList()
			},
			changePageSize: function(value) {
				this.perPage = value
				this.getOnboardingJourneyList()
			},
			onFiltered: function() {},
			clearFilter() {
				this.selectedBranch = this.employeeFilterBranch[0]
				this.getOnboardingJourneyList()
			},
			applyFilter() {
				this.getOnboardingJourneyList()
			},
			deleteOnboardEmployee: function(id) {
				console.log(id)
				//console.log(this.tableData.findIndex(data => data.id == id))
				this.axios.post('onboard-employee/delete', {
					onboardEmployeeId: id,
				}).then((result) => {
					console.log(result.data)
					//window.location.reload()
				}).catch((error) => {
					console.log(error)
				})
			},
		},
	};
</script>
<style scoped>
	.page-content{
		min-height: 750px;
	}
	.card-body {
		padding: 1.25rem 1.25rem !important;
	}
	.edit-link {
		padding: 0.47rem 0.75rem;
		font-size: 19px;
	}
	.b-table tbody tr td {
		vertical-align: middle;
	}
	.offer-status {
		padding: 5px 8px;
	}
	.offer-status.accepted {
		border: 1px solid #099028;
		background-color: #8CF8A5;
		color: #099028;
	}
	.offer-status.rejected {
		border: 1px solid #800B0B;
		background-color: #F57272;
		color: #800B0B;
	}
	.btn, .btn:hover, .btn:active, .btn:focus {
		background: transparent;
		color: #EB455F;
		outline: 0 none !important;
		box-shadow: none;
	}
	.btn.btn-custom-action {
		border: 0 none;
	}
	.filter-card {
		margin-top: -27px;
		border-top-left-radius: 0;
		border-top-right-radius: 0;
	}
</style>
<template>
	<Layout>
		<PageHeader :items="items" />
		<div class="row">
			<div class="col-12">
				<div class="card mb-4 filter-card">
					<div class="card-body">
						<div class="row inner mb-2">
							<div class="col-md-4">
								<label for="branch">Select Branch</label>
								<multiselect id="branch" v-model="selectedBranch" :options="employeeFilterBranch" placeholder="Select Branch" :multiple="false" track-by="name" label="name" :allow-empty="false">
									<template slot="singleLabel" slot-scope="{ option }">{{ option.name }}</template>
								</multiselect>
							</div>
							<div class="col-md-2" style="width: auto;">
								<button class="btn btn-outline-primary mt-4" v-on:click="applyFilter()">Apply Filter</button>
							</div>
							<div class="col-md-2" style="width: auto;">
								<button class="btn btn-outline-danger mt-4" v-on:click="clearFilter()">Clear Filter</button>
							</div>
						</div>
					</div>
				</div>
				<div class="card">
					<div class="card-body">
						<div class="row mb-4">
							<div class="col-sm-12 col-md-4">
								<!-- <div class="btn-group" role="group">
									<button class="btn btn-outline-primary" type="button">
										<span>Download Report</span>
										<b-spinner v-if="excelDownloading" small type="grow"></b-spinner>
										<b v-if="excelDownloading">Loading...</b>
									</button>
								</div> -->
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_length" class="dataTables_length">
									<label class="d-inline-flex align-items-center">Show &nbsp;&nbsp;<b-form-select style="margin-left:5px; margin-right:5px" v-model="perPage" size="sm" :options="pageOptions" @change="changePageSize"></b-form-select>&nbsp;&nbsp;&nbsp;entries</label>
								</div>
							</div>
							<div class="col-sm-12 col-md-4">
								<div id="tickets-table_filter" class="dataTables_filter text-md-end">
									<label class="d-inline-flex align-items-center">Search:<b-form-input v-model="filter" type="search" placeholder="Search..." class="form-control form-control-sm ms-2" @keyup="searchFilter(filter)"></b-form-input></label>
								</div>
							</div>
						</div>
						<div class="table-responsive mb-0">
							<b-table
								striped
								hover
								outlined
								bordered
								:items="tableData"
								:fields="fields"
								thead-class="bg-transparent"
								responsive="sm"
								:per-page="0"
								:current-page="currentPage"
								:sort-by.sync="sortBy"
								:sort-desc.sync="sortDesc"
								fixed-header
								:busy="isReportTableBusy"
								show-empty>
								<template #table-busy>
									<div class="text-center text-danger my-2">
										<b-spinner variant="primary" class="align-middle" type="grow"></b-spinner>
										<strong class="text-primary">Loading...</strong>
									</div>
								</template>
								<template v-slot:cell(actions)="{ item }">
									<router-link class="edit-link" :to="{
										name: 'view-onboarding-journey',
										params: {
											onboardingId: item._id,
										},
									}">
										<i class="mdi mdi-eye"></i>
									</router-link>
									<b-button class="btn-custom-action" variant="danger" @click="deleteOnboardEmployee(item._id)">
										<i class="mdi mdi-delete"></i>
									</b-button>
								</template>
								<template v-slot:cell(offerLetterAcceptRejectStatus)="{ item }">
									<span>{{ item.offerLetterAcceptRejectStatus }}</span>
									<!--<span v-if="item.offerLetterAcceptRejectStatus == 'accept'" class="offer-status accepted">Accepted</span>
									<span v-if="!item.offerLetterAcceptRejectStatus == 'reject'" class="offer-status rejected">Rejected</span>-->
								</template>
								<template v-slot:cell(joiningDate)="{ item }">{{ moment(item.joiningDate).format('DD-MM-YYYY') }}</template>
								<template v-slot:cell(offerExpiry)="{ item }">{{ moment(item.offerExpiry).format('DD-MM-YYYY') }}</template>
								<template v-slot:cell(createdAt)="{ item }">{{ moment(item.createdAt).format('DD-MM-YYYY') }}</template>
							</b-table>
						</div>
						<div class="row">
							<div class="col">
								<div class="dataTables_paginate paging_simple_numbers float-end">
									<ul class="pagination pagination-rounded mb-0">
										<b-pagination
											v-model="currentPage"
											:total-rows="rows"
											:per-page="perPage"
											@change="changePage" />
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</Layout>
</template>